import React from 'react'

const PreLoading = () => {
  return (
    <div className='container mx-auto'>
    <div className='pre'>
    
        <img alt='loader' className='cube' src='../assets/img/symbol.png'/>
      </div>
    </div>
  )
}

export default PreLoading