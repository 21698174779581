import './App.css';
import React, { useState, useEffect } from "react";
// import JsonData from "./data/data.json";
import { useTranslation } from 'react-i18next';

import { BrowserRouter as Router } from 'react-router-dom';
import PreLoading from './components/PreLoading';

import HomePage from './pages/HomePage';

function App() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  // Determine the current language
  const currentLanguage = t('language.direction');

   // Define font families and weights for each language
   const arabicFontFamily = 'Hacen-Maghreb';
   const arabicFontWeight = 400; // Adjust as needed
   const englishFontFamily = 'Darker Grotesque'; // Replace with your English font
   const englishFontWeight = 600; // Adjust as needed
 
   // Apply font family and weight based on the language
   const fontFamily = currentLanguage === 'rtl' ? arabicFontFamily : englishFontFamily;
   const fontWeight = currentLanguage === 'rtl' ? arabicFontWeight : englishFontWeight;
 
   useEffect(() => {
    // Simulate loading process (e.g., data fetching, resource loading)
    setTimeout(() => {
      setLoading(false); // Set loading state to false after 2 seconds (simulated loading time)
    }, 2000);
  }, []);


   return (
     <div className="App" style={{ fontFamily: `${fontFamily}, sans-serif`, fontWeight }}>
       {/* Your app content */}
      <Router>
      {loading ? <PreLoading /> : <HomePage />}
      </Router>
    </div>
  );
}

export default App;
