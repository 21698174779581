import React from 'react'

const Copyright = (props) => {
    const currentYear = new Date().getFullYear();

  return (
    <footer className="pb-6 sm:pb-0 text-white text-center">
      <p>{props.data} &copy; {currentYear}</p>
    </footer>
  )
}

export default Copyright