import React from "react";
import { useTranslation } from "react-i18next";
import { FaLinkedin, FaWhatsappSquare, FaTwitterSquare,FaInstagramSquare } from "react-icons/fa";


import Copyright from "./Copyright";
const Footer = () => {
  const { t } = useTranslation();

  return (
    <section id="contact" className="relative h-auto pt-24 pb-10">
      <div className="container mx-auto">
        <div className="flex justify-center pb-16">
          <div className="sm:grid grid-cols-5 gap-2">
            <div className="mb-10 mt-4 sm:px-4 md:px-16 col-span-1">
              <div className=" absolute right-0 bottom-0">
                <img
                  className="h-16 w-56 sm:h-24 md:h-32 md:w-72"
                  src="../assets/img/footer.png"
                  alt="Left "
                />
              </div>

              <div className="flex items-center">
                <img
                  className="w-32 sm:w-48 md:w-64"
                  src="../assets/img/logo.png"
                  alt="Logo"
                />
              </div>
            </div>

            <div className="mb-4 mt-4 px-2 col-span-1">
              <h3 className="pb-4">{t("Footer.urls.title")}</h3>
              <div className="flex flex-col">
                {t("Nav.elements").map((item, index) => (
                  <div key={index} className="flex items-center">
                    {/* {t("language.direction") === "rtl" ? (
                      <FaArrowLeft color="yellow" className="ml-2 mx-2 mb-2" />
                    ) : (
                      <FaArrowRight color="yellow" className="ml-2 mx-2 mb-2" />
                    )} */}
                    <img
                      className="w-4 h-4 mb-2 ml-2 mx-2"
                      src="../assets/img/yellow-rhombic.png"
                      alt="icon"
                    />
                    <a href={item.url} className="pb-2">
                      {item.label}
                    </a>
                  </div>
                ))}
              </div>
            </div>

            {t("Footer.cols").map((item) => (
              <div className="mb-4 mt-4 px-2 col-span-1">
                <div className="flex flex-col">
                  <h3 className="pb-4">{item.title}</h3>
                  <div className="flex flex-col">
                    {item.elements.map((item, index) => (
                      <div key={index} className="flex items-center">
                        <img
                          className="w-4 h-4 mb-2"
                          src="../assets/img/yellow-rhombic.png"
                          alt="icon"
                        />
                        <a
                          target="_blank"
                          href={item.url}
                          className="pb-2 mr-2 ml-2"
                        >
                          {item.label}
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}

            <div className=" mt-4 px-2 col-span-1">
              <h3>{t("Footer.social.desc")}</h3>
              <div className="flex ">
                <div className="mb-4 mt-4 px-2 ">
                  <a
                    href={t("Footer.social.linkedin")}
                    className="larger_size hover:scale-110"
                  >
                    <FaLinkedin className="yellow-color hover:scale-125" />
                  </a>
                </div>
                <div className="mb-4 mt-4 px-2">
                  <a
                    href={t("Footer.social.twitter")}
                    className="larger_size hover:scale-110"
                  >
                    <FaTwitterSquare className="yellow-color hover:scale-125" />
                  </a>
                </div>
                <div className="mb-4 mt-4 px-2">
                  <a
                    href={t("Footer.social.whatsapp")}
                    className="larger_size hover:scale-110"
                  >
                    <FaWhatsappSquare className="yellow-color hover:scale-125" />
                  </a>
                </div>
                <div className="mb-4 mt-4 px-2">
                  <a
                    href={t("Footer.social.instagram")}
                    className="larger_size hover:scale-110"
                  >
                    <FaInstagramSquare className="yellow-color hover:scale-125" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Copyright data={t("Footer.copyright")} />
      </div>
    </section>
  );
};

export default Footer;
