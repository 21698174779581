import {React, useEffect, useState} from 'react'
import i18next from 'i18next'
import cookies from 'js-cookie';
import i18n from '../i18n';

const ChangeLangBtn = () => {
    const lng = cookies.get("i18next") || "ar";

    useEffect(()=>{
      window.document.dir = i18n.dir();
    }, [lng]);
  
  
    
     const handleChangeLanguage = (lang) =>{
        i18next.changeLanguage(lang)
 }

  
  return (
   
	<div class="flex flex-row items-center right-1 ">
    	<button  onClick={() => handleChangeLanguage("ar") } 
      class={`p-2 flex flex-row items-center text-sm font-medium ${
        lng === 'ar' ? 'text-yellow-300' : ''
      }`}
                    >
		
                        <span class="text-md" style={{fontFamily:"'Hacen-Maghreb',  sans-serif"}}>عربي</span>
                       
                    </button>
      <button  onClick={() => handleChangeLanguage("en")}
       class={`p-2 flex flex-row items-center text-sm font-medium ${
        lng === 'en' ? 'text-yellow-300' : ''
      }`}
                    >
	
                      
                      <span class="text-md "style={{fontFamily:"'Darker Grotesque',  sans-serif", fontWeight:"600"}}>English</span>

                    </button>
                    </div>
//   <div>

// <label class="relative inline-flex cursor-pointer items-center">
//     <input id="switch-3" type="checkbox" class="peer sr-only" />
//     <label for="switch-3" class="hidden"></label>
//     <div class="peer h-0.5 w-11 rounded border bg-gray-200 after:absolute after:-top-1 after:left-0 after:h-4 after:w-4 after:rounded-md after:border after:border-yellow-300 after:bg-yellow-300 after:transition-all after:content-[''] peer-checked:bg-green-300 peer-checked:after:translate-x-full peer-focus:ring-green-300"></div>
//   </label>
// </div>
  )
}

export default ChangeLangBtn