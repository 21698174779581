import React from 'react'
import Nav from '../components/Nav';
import Hero from '../components/Hero';
import Clients from '../components/Clients';
import AboutUs from '../components/AboutUs';
import Services from '../components/Services';
import Products from '../components/Products';
import ContactUs from '../components/ContactUs';
import Footer from '../components/Footer';

const HomePage = () => {
  return (
    <div>
      <Nav />
      <Hero />
      <AboutUs />
      <Clients />
      <Services />
      <Products />
      <ContactUs />
      <Footer />
    </div>
  )
}

export default HomePage