import React from 'react'
import { useTranslation } from 'react-i18next'
import { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

const Services = () => {
  const { t } = useTranslation()
  const animationDelay = ['600', '900', '1200'];
  useEffect(() => {
    AOS.init();


  }, []);
  return (
    <section id="services" className="px-8 py-6 md:h-auto bg-cover bg-[#020055]">
      <div data-aos="fade-up"  className="container md:px-24 mx-auto my-16 relative">
        <div>
          <h5 className='text-white mb-3 mt-2'>{t('Services2.title')}</h5>
          <h2 className='text-white mb-3 mt-2'>{t('Services2.description')}</h2>
          <p>{t('Services2.sub_description')}</p>
        </div>

        <div className='flex flex-col lg:grid lg:grid-cols-3 space-y-4 lg:space-y-0 lg:gap-4 justify-evenly items-center pt-16'>
          {t('Services2.Services').map((item, index) => (
            <div>
              <div data-aos="flip-left"  data-aos-delay={`${animationDelay[index]}`} class="h-80 max-w-sm bg-[#020055] text-white border border-[#ffffff33] p-5 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <img class="rounded-t-lg w-14" src="../assets/img/x_icon.png" alt="" />
                <div class="p-5">
                  <h5 class="mb-2 text-2xl font-bold tracking-tight dark:text-white">
                    {item.title}
                  </h5>
                  <p class="mb-3 font-normal dark:text-gray-400">{item.text}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
