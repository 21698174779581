/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css"; // This imports the base CSS
import "swiper/css/navigation"; // Optional: if you are using navigation like arrows
import "swiper/css/pagination"; // Optional: if you are using pagination like dots

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import required modules
import { Pagination } from "swiper/modules";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles

function Products() {
  const { t, i18n } = useTranslation();

  const isRTL = i18n.language === 'ar'; // Assuming 'ar' is the code for Arabic

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    rtl: isRTL
  };

  useEffect(() => {
    AOS.init();

    document.getElementsByClassName('slick-track')[0].style.direction = isRTL ? 'rtl' : 'ltr';

  }, [isRTL]);

  const products = t("Products.Solutions", { returnObjects: true });
  const displayProducts = isRTL ? [...products].reverse() : products;

  return (
    <section id="products" className="px-8 py-6 h-auto bg-white">
      <div data-aos="fade-up" className="container md:px-24 mx-auto my-16">
        <br />
        <h5 className="text-primary">{t("Products.title")}</h5>
        <br />
        <h2 className="text-primary">{t("Products.title2")}</h2>
        <br />
        <p className="text-primary">{t("Products.des")}</p>
        <br />
        <Slider {...settings}>
          {displayProducts.map((item, index) => (
            <div>
              <div className="w-full p-4 text-center bg-[#020055] border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                <div className="grid grid-cols-2 md:grid-cols-3 items-center">
                  <div className="md:col-span-2 text-start flex flex-col justify-evenly">
                    <h1>{item.name}</h1>
                    <p>{item.text}</p>
                    <span className="bg-transparent w-fit text-yellow-300 text-xs font-medium me-2 px-3 py-3 my-6 rounded dark:bg-gray-700 dark:text-yellow-300 border border-yellow-300">
                    <a href="#contact"> {t("Products.btn")}{" "}</a>
                    </span>
                  </div>
                  <div className="flex w-fit  mx-4 justify-center items-center md:col-span-1">
                    <img
                      src={item.icon}
                      alt={item.name}
                      className="rounded-lg"
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
}

export default Products;
