import { React, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CountUp from "react-countup";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles

const Clients = () => {
  const { t, i18n } = useTranslation();
  const clients = i18n.t("Clients", { returnObjects: true }); // This tells i18next to return the object
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    //  Block start
    <section className="px-16 pt-16 h-auto pb-16">
      <div
        data-aos="flip-left"
        data-aos-duration="2000"
        className=" container mx-auto "
      >
        <div className=" space-y-8 md:grid md:grid-cols-3 lg:grid-cols-3 md:gap-8 xl:gap-12 md:space-y-0 mr-10">
          {clients.map((client, index) => (
            <div key={index} className="p-4 rounded">
              <div className="flex justify-center items-center mb-4 w-20 h-20 rounded lg:h-25 lg:w-26">
                <img
                  src={client.image} // Assuming this path is correctly configured
                  alt={`Icon ${index + 1}`}
                  className="w-full h-full object-cover rounded"
                />
              </div>
              <div className="flex items-center text-lightBlue">
                <img
                  src="../assets/img/plus-icon.png"
                  alt="Shared Icon"
                  className="w-10 h-10 ml-2" // Adjusted size
                />
                <div>
                  <CountUp
                    start={0}
                    end={client.number}
                    duration={2.5}
                    className="text-5xl font-bold"
                  />{" "}
                  {/* <span className="text-5xl font-bold">{client.number}</span>{" "} */}
                  {/* Larger size for number */}
                  <span className="text-xl">{client.text}</span>{" "}
                  {/* Default size for text */}
                </div>
              </div>
              {/* <p className="text-lightGrey ">{client.description}</p> */}
            </div>
          ))}
        </div>
      </div>
    </section>
    //Block ends
  );
};

export default Clients;
