import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';

import i18n from 'i18next';

i18n.use(initReactI18next)
.use(LanguageDetector)
.use(HttpBackend)
.init({
  debug: true,
  backend: {
    loadPath: '/assets/locales/{{lng}}/{{ns}}.json',
  },
  returnObjects: true,
  fallbackLng: 'en', // Fallback language
    detection: {
      order: [ 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      caches: ['cookie']
    },
});
// // initialized and ready to go!
// // i18next is already initialized, because the translation resources where passed via init function
// document.getElementById('output').innerHTML = i18n.t('Header.title');

export default i18n;