import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaArrowLeft, FaArrowRight, FaFileDownload } from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import { saveAs } from "file-saver";
import Profile from "../WetaanCompanyProfile-Ar.pdf";

const Hero = (props) => {
  const { t, i18n } = useTranslation();
  // const logoRef = useRef(null); // Create a ref

  useEffect(() => {
    AOS.init();
  }, [t]);

  const downloadProfile = () => {
    saveAs(Profile, "الملف التعريفي لوتان.pdf");
  };

  return (
    <section
      id="home"
      className="hero h-screen mb-20 bg-cover bg-center flex flex-col justify-center items-center"
      style={{ backgroundImage: `url('../assets/img/hero.png')` }}
    >
      <div className="absolute left-0">
        <img className="h-48 md:h-96" src="../assets/img/2.png" alt="Left" />
      </div>
      <div className="absolute right-0 top-6 pt-6">
        <img className="h-48 md:h-96" src="../assets/img/1.png" alt="Right" />
      </div>
      <div className=" sm:mt-10 align-center text-center mb-24 px-10 md:px-16">
        {/* <img className="absolute pt-20 mt-8" src='../assets/img/symbol.png'/> */}
        {/* <img className="w-64 md:w-96 h-auto mb-4" src='../assets/img/logo.png' alt="Logo"/> */}

        <div className="block mb-10 mx-4 ">
          <h1
            data-aos="zoom-in"
            data-aos-delay="300"
            className="text-white mt-5 "
          >
            {t("Header.title")}
          </h1>
          <h1
            data-aos="zoom-in"
            data-aos-delay="500"
            className="text-white sm:px-32 lg:px-64"
          >
            {t("Header.paragraph")}
          </h1>
          <p
            data-aos="fade-up"
            data-aos-delay="800"
            className="text-white mt-8 "
          >
            {t("Header.paragraph2")}
          </p>
          {/* <img className="h-7 w-7 mt-5 mx-2 mb-10" src='../assets/img/symbol.png' alt="Symbol"/> */}
          {/* ref={logoRef}  */}
        </div>
        <div className="flex flex-col sm:flex-row justify-center items-center space-y-2 sm:space-y-0 sm:space-x-4 p-4 gap-4">
          <button
            onClick={() =>
              document
                .getElementById("contact")
                .scrollIntoView({ behavior: "smooth" })
            }
            data-aos="zoom-in-down"
            data-aos-delay="2000"
            className="py-2 px-5 text-primary bg-secondary hover:bg-yellow-300  flex items-center  hover:lightBlue  rounded-lg transition-all duration-300 "
          >
            {t("Header.contactus")}
            {i18n.language === "ar" ? (
              <FaArrowLeft className="text-primary mx-2" />
            ) : (
              <FaArrowRight className="text-primary mx-2" />
            )}
          </button>
          <button
            onClick={downloadProfile}
            data-aos="zoom-in-down"
            data-aos-delay="2000"
            className="py-2 px-4 text-secondary border border-secondary hover:border-yellow-400 hover:text-yellow-400 flex items-center  hover:lightBlue  rounded-lg transition-all duration-300 "
          >
            {t("Header.profile")}
            <FaFileDownload className=" mx-2 " />
          </button>
        </div>
      </div>
    </section>
  );
};

export default Hero;
