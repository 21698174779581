import { React, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles

const AboutUs = () => {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    
   
    <section id="about" className="h-auto px-8 py-6 ">
    <div data-aos="fade-up" className="container mx-auto px-6 md:px-24 my-16">
      <div className="flex flex-col md:flex-row mb-6">
        {/* Content1 */}
        <div  className="w-full md:w-1/2 p-0">
          {/* <div className={`h-96 flex flex-col justify-center w-full max-w-lg mx-4`}> */}

            <h5>{t("About.title")}</h5>
          </div>
        </div>
        <div className="flex flex-col md:flex-row">
          <div className={`w-full md:w-1/2 pr-${t("About.margin")}`}
         >
            <div className="relative md:flex flex-col mb-12" >
              <h2 className="text-white">{t("About.who1")}</h2>
              {/* <span className='md:absolute p-16'>
            <img className=' pt-6 cube-img' src='../assets/img/cube.png' />
            </span> */}
            </div>
            </div>
           
        

          {/* content2 */}
          <div
            data-aos="fade-up"
            data-aos-delay="800"
            className="w-full md:w-1/2 p-0"
          >
            <div className="bg-lightGrey bg-opacity-10 rounded-lg px-16">
              {t("About.des").map((item, index) => (
                <p
                  key={index}
                  data-aos="fade-up"
                  data-aos-delay="500"
                  className={`font-normal text-blue-gray-500 py-6 text-${t(
                    "About.dir"
                  )} text-white`}
                >
                  {item}
                </p>
              ))}
            </div>
          </div>
        
      </div>
      </div>
     
    </section>
  );
};

export default AboutUs;
