import {React, useState, useEffect} from 'react'
import { Navbar } from "flowbite-react";
import { Link } from 'react-router-dom';
import {useTranslation} from 'react-i18next'
import ChangeLangBtn from './ChangeLangBtn';
import { Link as ScrollLink } from 'react-scroll';

const Nav = () => {
  const {t} = useTranslation()
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (

    <Navbar fluid className={`nav py-4 fixed justify-between items-center w-full ${scrolled ? 'scrolled' : ''}`}>
      <Link  as={Link} href="https://wetaan.com" className="navbar-brand">
        <img src='../assets/img/logo.png' className="sm:mr-3 h-6 sm:h-9" alt="Wetaan Logo" />
      </Link >
      <div className="flex md:order-2 items-center">
      <ChangeLangBtn/>

        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
      </div>
      <Navbar.Collapse  id="responsive-navbar-nav" >
      {t('Nav.elements').map((item, index) =>(
       
       <ScrollLink
      key={index}
      to={item.url.substring(1)} 
      spy={true}
      smooth={true}
      duration={500}
      className="py-2 px-3 ml-3 hover:border-b border-lightBlue hover:text-lightBlue inline-block"
      activeClassName="active"
      style={{ cursor: 'pointer', fontSize: '16px' }}
    >
      {item.label}
    </ScrollLink>
   
   
    
        ))}
        </Navbar.Collapse>

        </Navbar>
      
  );
}

  


export default Nav